var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title": "收货人名称、手机号",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c("div", { staticClass: "marT10 marB10 x-f" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("取货日期"),
                          ]),
                          _c("el-date-picker", {
                            staticClass: "marR15",
                            attrs: {
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              size: "mini",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.datetimeGet,
                              callback: function ($$v) {
                                _vm.datetimeGet = $$v
                              },
                              expression: "datetimeGet",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("状态"),
                          ]),
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: {
                                data: _vm.orderStatusList,
                                labels: [
                                  { title: "名称", label: "orderStatusName" },
                                ],
                                value: "orderStatusId",
                                label: "orderStatusName",
                              },
                            },
                            model: {
                              value: _vm.queryParams.orderStatuss,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "orderStatuss", $$v)
                              },
                              expression: "queryParams.orderStatuss",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f marR15" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("预定门店"),
                          ]),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listShop",
                                option: {
                                  option: {
                                    buttons: [
                                      {
                                        type: "more",
                                        option: {
                                          title: "选择门店",
                                          width: 1250,
                                          type: "TreeAndTable",
                                          formData: this.$dialog({
                                            key: "shop",
                                          }),
                                        },
                                      },
                                    ],
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.predetermineShopIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "predetermineShopIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.predetermineShopIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f marR15" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("取货门店"),
                          ]),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listShop",
                                option: {
                                  option: {
                                    buttons: [
                                      {
                                        type: "more",
                                        option: {
                                          title: "选择门店",
                                          width: 1250,
                                          type: "TreeAndTable",
                                          formData: this.$dialog({
                                            key: "shop",
                                          }),
                                        },
                                      },
                                    ],
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.shopIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "shopIds", $$v)
                              },
                              expression: "queryParams.shopIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "x-f marR15" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("配送模式"),
                        ]),
                        _c("SelectLocal", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: {
                              data: _vm.dict.type.fetch_type,
                              labels: [{ title: "名称", label: "label" }],
                              value: "value",
                              label: "label",
                            },
                          },
                          model: {
                            value: _vm.queryParams.fetchTypes,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "fetchTypes", $$v)
                            },
                            expression: "queryParams.fetchTypes",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "orderInfo",
          attrs: { "card-title": "门店预订查询" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "预订单明细查询", name: "detail" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable1",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订时间",
                                align: "center",
                                prop: "billDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订小票号",
                                align: "center",
                                prop: "billNo",
                                width: "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货人/客户名称",
                                align: "center",
                                prop: "receiverName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "receiverPhone",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡号",
                                align: "center",
                                prop: "vipNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员名称",
                                align: "center",
                                prop: "vipName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                sortable: "",
                                align: "center",
                                prop: "unitPrice",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "加值属性",
                                align: "center",
                                prop: "machining",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编号",
                                align: "center",
                                prop: "machineNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机",
                                align: "center",
                                prop: "machineName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送方式",
                                align: "center",
                                prop: "fetchTypeName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货门店",
                                align: "center",
                                prop: "shopName",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货地址",
                                align: "center",
                                prop: "receiverRegionAddress",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订门店",
                                align: "center",
                                prop: "predetermineShopName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产部门",
                                align: "center",
                                prop: "orderShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "取货小票号",
                                align: "center",
                                prop: "pickUpBillPosNo",
                                width: "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货时间",
                                align: "center",
                                prop: "reachDate",
                                minWidth: "200",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "实际取货时间",
                                align: "center",
                                prop: "pickUpDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                align: "center",
                                prop: "orderStatusName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "billRemark",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "预订单查询", name: "summary" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订时间",
                                align: "center",
                                prop: "billDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订小票号",
                                align: "center",
                                prop: "billPosNo",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(
                                                scope.row.billId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.billPosNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货人/客户名称",
                                align: "center",
                                prop: "receiverName",
                                width: "125",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "receiverPhone",
                                width: "150",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡号",
                                align: "center",
                                prop: "vipNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员名称",
                                align: "center",
                                prop: "vipName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编号",
                                align: "center",
                                prop: "machineNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机",
                                align: "center",
                                prop: "machineName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送方式",
                                align: "center",
                                prop: "fetchTypeName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货门店",
                                align: "center",
                                prop: "shopName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货地址",
                                align: "center",
                                prop: "receiverRegionAddress",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订门店",
                                align: "center",
                                prop: "predetermineShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产部门",
                                align: "center",
                                prop: "orderShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "取货小票号",
                                align: "center",
                                prop: "pickUpBillPosNo",
                                width: "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货时间",
                                align: "center",
                                prop: "reachDate",
                                minWidth: "200",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "实际取货时间",
                                align: "center",
                                prop: "pickUpDate",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                align: "center",
                                prop: "orderStatusName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "billRemark",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "预订收款查询", name: "payment" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订时间",
                                align: "center",
                                prop: "billDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订小票号",
                                align: "center",
                                prop: "billPosNo",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(
                                                scope.row.billId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.billPosNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货人/客户名称",
                                align: "center",
                                prop: "receiverName",
                                width: "125",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "receiverPhone",
                                width: "150",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡号",
                                align: "center",
                                prop: "vipNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员名称",
                                align: "center",
                                prop: "vipName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编号",
                                align: "center",
                                prop: "machineNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机",
                                align: "center",
                                prop: "machineName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送方式",
                                align: "center",
                                prop: "fetchTypeName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货门店",
                                align: "center",
                                prop: "shopName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货地址",
                                align: "center",
                                prop: "receiverRegionAddress",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订门店",
                                align: "center",
                                prop: "predetermineShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产部门",
                                align: "center",
                                prop: "orderShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "取货小票号",
                                align: "center",
                                prop: "pickUpBillPosNo",
                                width: "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货时间",
                                align: "center",
                                prop: "reachDate",
                                minWidth: "200",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "实际取货时间",
                                align: "center",
                                prop: "pickUpDate",
                                minWidth: "160",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "付款方式",
                                  align: "center",
                                  prop: "payModeId",
                                },
                              },
                              _vm._l(_vm.payModeList, function (item, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: item.payModeName,
                                    prop:
                                      item.payModeName + "." + item.payModeId,
                                    align: "center",
                                    sortable: "",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.payDetailList
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.payMoneyCompute(
                                                          item.payModeId,
                                                          scope.row
                                                            .payDetailList
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _c("span", [_vm._v("0")]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "收款金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                align: "center",
                                prop: "orderStatusName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "billRemark",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "预订退款查询", name: "refund" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订时间",
                                align: "center",
                                prop: "billDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订小票号",
                                align: "center",
                                prop: "billPosNo",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(
                                                scope.row.billId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.billPosNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货人/客户名称",
                                align: "center",
                                prop: "receiverName",
                                width: "125",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "receiverPhone",
                                width: "150",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员卡号",
                                align: "center",
                                prop: "vipNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "会员名称",
                                align: "center",
                                prop: "vipName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编号",
                                align: "center",
                                prop: "machineNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机",
                                align: "center",
                                prop: "machineName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员编号",
                                align: "center",
                                prop: "cashierNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银员",
                                align: "center",
                                prop: "cashierName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送方式",
                                align: "center",
                                prop: "fetchTypeName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货门店",
                                align: "center",
                                prop: "shopName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货地址",
                                align: "center",
                                prop: "receiverRegionAddress",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "预订门店",
                                align: "center",
                                prop: "predetermineShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产部门",
                                align: "center",
                                prop: "orderShopName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "取货小票号",
                                align: "center",
                                prop: "pickUpBillPosNo",
                                width: "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "配送/取货时间",
                                align: "center",
                                prop: "reachDate",
                                minWidth: "200",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "实际取货时间",
                                align: "center",
                                prop: "pickUpDate",
                                minWidth: "160",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "付款方式",
                                  align: "center",
                                  prop: "payModeId",
                                },
                              },
                              _vm._l(_vm.payModeList, function (item, index) {
                                return _c("el-table-column", {
                                  key: index,
                                  attrs: {
                                    label: item.payModeName,
                                    prop:
                                      item.payModeName + "." + item.payModeId,
                                    align: "center",
                                    sortable: "",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.payDetailList
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.payMoneyCompute(
                                                          item.payModeId,
                                                          scope.row
                                                            .payDetailList
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _c("span", [_vm._v("0")]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "收款金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "状态",
                                align: "center",
                                prop: "orderStatusName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "billRemark",
                                width: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "预订查询汇总(按日期)",
                          name: "collectDate",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "日期",
                                align: "center",
                                prop: "billDate",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                                minWidth: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "预订查询汇总(按门店)",
                          name: "collectShop",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编码",
                                align: "center",
                                prop: "shopNo",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                minWidth: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                                minWidth: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "预订查询汇总(按商品)",
                          name: "collectGoods",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                minWidth: "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品规格",
                                align: "center",
                                prop: "goodsSpec",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                sortable: "",
                                align: "center",
                                prop: "saleMoney",
                                minWidth: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "总优惠金额",
                                sortable: "",
                                align: "center",
                                prop: "agioMoney",
                                minWidth: "120",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        "is-select-all": false,
                        "total-com": _vm.total,
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }