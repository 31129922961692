<template>
  <passengerFlowComponent />
</template>

<script>
import passengerFlowComponent from './components'
export default {
  name: 'passengerFlow',
  components: { passengerFlowComponent }
}
</script>
