var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title": "输入门店编号、门店名称",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "marT10",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f marB10" },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.activeName == "receipt" ||
                                  _vm.activeName == "materialOrder",
                                expression:
                                  "\n                activeName == 'receipt' || activeName == 'materialOrder'\n              ",
                              },
                            ],
                            staticClass: "x-f",
                          },
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("到货日期"),
                            ]),
                            _c("el-date-picker", {
                              staticClass: "marR15",
                              staticStyle: { width: "250px" },
                              attrs: {
                                size: "mini",
                                type: "date",
                                placeholder: "到货日期",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.queryParams.arrivalDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "arrivalDate", $$v)
                                },
                                expression: "queryParams.arrivalDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listGoods",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "goods",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.goodsIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "goodsIds", $$v)
                            },
                            expression: "queryParams.goodsIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品分类"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listCategory",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品类别",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "category",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.categoryIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "categoryIds", $$v)
                            },
                            expression: "queryParams.categoryIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "x-f" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeName == "scrap",
                              expression: "activeName == 'scrap'",
                            },
                          ],
                          staticClass: "x-f",
                        },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("报损原因"),
                          ]),
                          _c("SelectLocal", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: {
                                data: _vm.busiDicts.shop_scrap_type,
                                labels: [{ title: "名称", label: "dictLabel" }],
                                value: "dictValue",
                                label: "dictLabel",
                              },
                            },
                            model: {
                              value: _vm.queryParams.scrapTypeIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "scrapTypeIds", $$v)
                              },
                              expression: "queryParams.scrapTypeIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeName == "purchase",
                              expression: "activeName == 'purchase'",
                            },
                          ],
                          staticClass: "x-f",
                        },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("供应商"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listSupplier",
                                option: {
                                  option: {
                                    buttons: [
                                      {
                                        type: "more",
                                        option: {
                                          title: "选择供应商",
                                          width: 1250,
                                          type: "TreeAndTable",
                                          formData: this.$dialog({
                                            key: "supplier",
                                          }),
                                        },
                                      },
                                    ],
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.partnerIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "partnerIds", $$v)
                              },
                              expression: "queryParams.partnerIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "门店单据查询" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店订货查询", name: "receipt" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable1",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产班次",
                                align: "center",
                                prop: "produceOrderName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订货仓库",
                                align: "center",
                                prop: "orderStoreName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂",
                                align: "center",
                                prop: "produceDeptName",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "到货日期",
                                align: "center",
                                prop: "arrivalDate",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                                sortable: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "门店原料订货查询",
                          name: "materialOrder",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "到货日期",
                                align: "center",
                                prop: "arrivalDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                                sortable: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店采购查询", name: "purchase" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "入库仓库",
                                align: "center",
                                prop: "storeName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "供应商",
                                align: "center",
                                prop: "partnerName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                                sortable: "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "含税单价",
                                align: "center",
                                prop: "unitTaxPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "税率",
                                align: "center",
                                prop: "taxRate",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "税额",
                                align: "center",
                                prop: "unitTaxMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "价税合计",
                                align: "center",
                                prop: "unitPriceTaxMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店报废查询", name: "scrap" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "报废原因",
                                align: "center",
                                prop: "scrapTypeName",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库",
                                align: "center",
                                prop: "storeName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店调入单查询", name: "shopIn" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "调入仓库",
                                align: "center",
                                prop: "inStoreName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "调出仓库",
                                align: "center",
                                prop: "outStoreName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "发货数量",
                                align: "center",
                                prop: "importUnitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收货数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店调出单查询", name: "shopOut" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "调出仓库",
                                align: "center",
                                prop: "outStoreName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "调入仓库",
                                align: "center",
                                prop: "inStoreName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "订货数量",
                                align: "center",
                                prop: "importUnitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "发货数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店生产查询", name: "produce" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库",
                                align: "center",
                                prop: "storeName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店原料消耗", name: "picking" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "仓库",
                                align: "center",
                                prop: "storeName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编码",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单价",
                                align: "center",
                                prop: "unitPrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "金额",
                                align: "center",
                                prop: "unitMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "门店盘点单查询", name: "check" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                prop: "billNo",
                                width: "160",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.billNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店编号",
                                align: "center",
                                prop: "shopNo",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘点仓库",
                                align: "center",
                                prop: "storeName",
                                width: "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别编号",
                                align: "center",
                                prop: "categoryNo",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "类别名称",
                                align: "center",
                                prop: "categoryName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "售价",
                                align: "center",
                                prop: "unitSalePrice",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘点数量",
                                align: "center",
                                prop: "unitQty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘点金额",
                                align: "center",
                                prop: "unitSaleMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘盈数量",
                                align: "center",
                                prop: "adjust_qty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘盈金额",
                                align: "center",
                                prop: "adjustMoney",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘亏数量",
                                align: "center",
                                prop: "lose_qty",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "盘亏金额",
                                align: "center",
                                prop: "loseMoney",
                                width: "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }