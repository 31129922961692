var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "门店名称、门店编号、商品编码、条码、商品类别",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listGoods",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择商品",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({
                                          key: "goods",
                                        }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.goodsIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "goodsIds", $$v)
                            },
                            expression: "queryParams.goodsIds",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "门店销售对比" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      key: Math.random(),
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          prop: "userId",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          prop: "goodsNo",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "条码",
                          align: "center",
                          prop: "barcode",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._l(_vm.tableshopList, function (item, index) {
                        return _c(
                          "el-table-column",
                          {
                            key: index,
                            attrs: {
                              label: item.shopName,
                              prop: item.shopName + "." + item.shopId,
                              align: "center",
                            },
                          },
                          [
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "数量",
                                  align: "center",
                                  width: "100",
                                  prop:
                                    "goodsShops." + item.shopId + ".unitQty",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payMoneyCompute(
                                                    item.shopId,
                                                    scope.row.goodsShops,
                                                    "unitQty"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "销售数量占比%",
                                  align: "center",
                                  width: "120",
                                  prop:
                                    "goodsShops." +
                                    item.shopId +
                                    ".unitQtyRate",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payMoneyCompute(
                                                    item.shopId,
                                                    scope.row.goodsShops,
                                                    "unitQtyRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "金额",
                                  align: "center",
                                  width: "100",
                                  prop:
                                    "goodsShops." + item.shopId + ".unitMoney",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payMoneyCompute(
                                                    item.shopId,
                                                    scope.row.goodsShops,
                                                    "unitMoney"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "销售金额占比%",
                                  align: "center",
                                  width: "120",
                                  prop:
                                    "goodsShops." +
                                    item.shopId +
                                    ".unitMoneyRate",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.payMoneyCompute(
                                                    item.shopId,
                                                    scope.row.goodsShops,
                                                    "unitMoneyRate"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }