var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    isBillDate: false,
                    "is-search-input": true,
                    "is-search-input-title": "门店编号、门店名称",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10 marB10" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("门店"),
                        ]),
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  buttons: [
                                    {
                                      type: "more",
                                      option: {
                                        title: "选择门店",
                                        width: 1250,
                                        type: "TreeAndTable",
                                        formData: this.$dialog({ key: "shop" }),
                                      },
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.shopIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "shopIds", $$v)
                            },
                            expression: "queryParams.shopIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "orderInfo", attrs: { "card-title": "门店业绩统计" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "月份销售完成比查询",
                          name: "saleMonth",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "月份",
                                align: "center",
                                prop: "yearMonth",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "目标金额",
                                sortable: "",
                                align: "center",
                                prop: "monthSale",
                              },
                            }),
                            _c(
                              "el-table-column",
                              { attrs: { label: "已完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.completeRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.completeRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "未完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.incompleteRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.incompleteRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "剩余天数",
                                align: "center",
                                prop: "residueDay",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "剩余每日目标",
                                align: "center",
                                prop: "residueDailyGoal",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "年度销售完成比查询",
                          name: "saleYear",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "年份",
                                align: "center",
                                prop: "yearMonth",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "目标金额",
                                sortable: "",
                                align: "center",
                                prop: "monthSale",
                              },
                            }),
                            _c(
                              "el-table-column",
                              { attrs: { label: "已完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.completeRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.completeRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "未完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.incompleteRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.incompleteRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "月份充值完成比查询",
                          name: "rechargeMonth",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "月份",
                                align: "center",
                                prop: "yearMonth",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "目标金额",
                                sortable: "",
                                align: "center",
                                prop: "monthSale",
                              },
                            }),
                            _c(
                              "el-table-column",
                              { attrs: { label: "已完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.completeRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.completeRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "未完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.incompleteRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.incompleteRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c("el-table-column", {
                              attrs: {
                                label: "剩余天数",
                                align: "center",
                                prop: "residueDay",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "剩余每日目标",
                                align: "center",
                                prop: "residueDailyGoal",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "年度充值完成比查询",
                          name: "rechargeYear",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            key: Math.random(),
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "summary-method": _vm.getSummaries,
                              height: _vm.tableHeight,
                              "max-height": _vm.tableHeight,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "门店",
                                align: "center",
                                prop: "shopName",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "年份",
                                align: "center",
                                prop: "yearMonth",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "目标金额",
                                sortable: "",
                                align: "center",
                                prop: "monthSale",
                              },
                            }),
                            _c(
                              "el-table-column",
                              { attrs: { label: "已完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "completeRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.completeRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.completeRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table-column",
                              { attrs: { label: "未完成", align: "center" } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "金额",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteMoney",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "百分比",
                                    sortable: "",
                                    align: "center",
                                    prop: "incompleteRate",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.incompleteRate
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.incompleteRate +
                                                        "%"
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }