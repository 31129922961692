<template>
  <SaleTotal />
</template>

<script>
import SaleTotal from './components'
export default {
  name: 'SaleTotal',
  components: { SaleTotal }
}
</script>
