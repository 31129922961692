<template>
  <StoreQuery />
</template>

<script>
import StoreQuery from './components'
export default {
  name: 'StoreQuery',
  components: { StoreQuery }
}
</script>
