<template>
  <overview />
</template>

<script>
import overview from './components'
export default {
  name: 'businessOverview',
  components: { overview }
}
</script>
