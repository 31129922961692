<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="输入门店编号、门店名称"
            @isShowHighCom="getIsShowHigh"
          />
          <!-- 条件 -->
          <div v-show="showHigh" class="marT10">
            <div class="x-f marB10">
              <div
                class="x-f"
                v-show="
                  activeName == 'receipt' || activeName == 'materialOrder'
                "
              >
                <span class="fS14MR10">到货日期</span>
                <el-date-picker
                  style="width: 250px"
                  size="mini"
                  class="marR15"
                  v-model="queryParams.arrivalDate"
                  type="date"
                  placeholder="到货日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
              <span class="fS14MR10">商品</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.goodsIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listGoods',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'goods' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">商品分类</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.categoryIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCategory',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品类别',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'category' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
            </div>
            <div class="x-f">
              <div class="x-f" v-show="activeName == 'scrap'">
                <span class="fS14MR10">报损原因</span>
                <SelectLocal
                  :multiple="true"
                  class="marR15"
                  v-model="queryParams.scrapTypeIds"
                  style="width: 250px"
                  :option="{
                    data: busiDicts.shop_scrap_type,
                    labels: [{ title: '名称', label: 'dictLabel' }],
                    value: 'dictValue',
                    label: 'dictLabel'
                  }"
                />
              </div>
              <div class="x-f" v-show="activeName == 'purchase'">
                <span class="fS14MR10">供应商</span>
                <SelectRemote
                  :multiple="true"
                  class="marR15"
                  v-model="queryParams.partnerIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listSupplier',
                      option: {
                        option: {
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择供应商',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'supplier' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店单据查询" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="门店订货查询" name="receipt">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="生产班次"
                  align="center"
                  prop="produceOrderName"
                  width="160"
                />
                <el-table-column
                  label="订货仓库"
                  align="center"
                  prop="orderStoreName"
                  width="160"
                />
                <el-table-column
                  label="生产工厂"
                  align="center"
                  prop="produceDeptName"
                  width="120"
                />
                <el-table-column
                  label="到货日期"
                  align="center"
                  prop="arrivalDate"
                  width="120"
                />
                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                  sortable
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                  sortable
                />

                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                  sortable
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店原料订货查询" name="materialOrder">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="到货日期"
                  align="center"
                  prop="arrivalDate"
                  width="100"
                />
                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                  sortable
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                  sortable
                />
                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                  sortable
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店采购查询" name="purchase">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="入库仓库"
                  align="center"
                  prop="storeName"
                  width="160"
                />
                <el-table-column
                  label="供应商"
                  align="center"
                  prop="partnerName"
                  width="160"
                />
                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                  sortable
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                  sortable
                />
                <el-table-column
                  label="含税单价"
                  align="center"
                  prop="unitTaxPrice"
                  width="150"
                />
                <el-table-column
                  label="税率"
                  align="center"
                  prop="taxRate"
                  width="150"
                />
                <el-table-column
                  label="税额"
                  align="center"
                  prop="unitTaxMoney"
                  width="150"
                />
                <el-table-column
                  label="价税合计"
                  align="center"
                  prop="unitPriceTaxMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店报废查询" name="scrap">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="报废原因"
                  align="center"
                  prop="scrapTypeName"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="仓库"
                  align="center"
                  prop="storeName"
                  width="160"
                />
                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />

                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店调入单查询" name="shopIn">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="调入仓库"
                  align="center"
                  prop="inStoreName"
                  width="160"
                />
                <el-table-column
                  label="调出仓库"
                  align="center"
                  prop="outStoreName"
                  width="160"
                />

                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="发货数量"
                  align="center"
                  prop="importUnitQty"
                  width="150"
                />
                <el-table-column
                  label="收货数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />

                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店调出单查询" name="shopOut">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="调出仓库"
                  align="center"
                  prop="outStoreName"
                  width="160"
                />
                <el-table-column
                  label="调入仓库"
                  align="center"
                  prop="inStoreName"
                  width="160"
                />

                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="订货数量"
                  align="center"
                  prop="importUnitQty"
                  width="150"
                />
                <el-table-column
                  label="发货数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店生产查询" name="produce">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="仓库"
                  align="center"
                  prop="storeName"
                  width="160"
                />
                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />

                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店原料消耗" name="picking">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="仓库"
                  align="center"
                  prop="storeName"
                  width="160"
                />
                <el-table-column
                  label="类别编码"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="单价"
                  align="center"
                  prop="unitPrice"
                  width="150"
                />
                <el-table-column
                  label="金额"
                  align="center"
                  prop="unitMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店盘点单查询" name="check">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                :max-height="tableHeight"
                :height="tableHeight"
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  prop="billNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.billNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  width="100"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  prop="shopNo"
                  width="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  width="160"
                />
                <el-table-column
                  label="盘点仓库"
                  align="center"
                  prop="storeName"
                  width="160"
                />
                <el-table-column
                  label="类别编号"
                  align="center"
                  prop="categoryNo"
                  width="120"
                />
                <el-table-column
                  label="类别名称"
                  align="center"
                  prop="categoryName"
                  width="150"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="150"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="150"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="150"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="150"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="150"
                />
                <el-table-column
                  label="售价"
                  align="center"
                  prop="unitSalePrice"
                  width="150"
                />
                <el-table-column
                  label="盘点数量"
                  align="center"
                  prop="unitQty"
                  width="150"
                />
                <el-table-column
                  label="盘点金额"
                  align="center"
                  prop="unitSaleMoney"
                  width="150"
                />
                <el-table-column
                  label="盘盈数量"
                  align="center"
                  prop="adjust_qty"
                  width="150"
                />
                <el-table-column
                  label="盘盈金额"
                  align="center"
                  prop="adjustMoney"
                  width="150"
                />
                <el-table-column
                  label="盘亏数量"
                  align="center"
                  prop="lose_qty"
                  width="150"
                />
                <el-table-column
                  label="盘亏金额"
                  align="center"
                  prop="loseMoney"
                  width="150"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  materialOrderListAPI,
  shopGoodsListAPI,
  ShopInListAPI,
  ShopOutListAPI,
  shopProduceListAPI,
  shopPurchaseListAPI,
  shopScrapListAPI,
  shopPickingListAPI,
  shopCheckListAPI,
  outStoreAPI
} from '@/api/shop/report/billQuery.js' //单据查询
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import business from '@/utils/mixin/business' //业务字典
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { fcount } from '@/utils'
export default {
  name: 'billQuery',
  mixins: [business],
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote,
    SelectLocal
  },
  data () {
    return {
      activeName: 'receipt',
      storeList: [], // 仓库数据
      outStoreList: [], //调出仓库
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNos', name: '单据编号' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      exportType: {
        receipt: '/api/system/shop/report/shop/bill/listShopBillOrderExport',
        materialOrder:
          '/api/system/shop/report/shop/bill/listShopBillMaterialOrderExport',
        purchase: '/api/system/shop/report/shop/bill/listShopPurchaseExport',
        scrap: '/api/system/shop/report/shop/bill/listShopScrapExport',
        shopIn: '/api/system/shop/report/shop/bill/listShopInExport',
        shopOut: '/api/system/shop/report/shop/bill/listShopOutExport',
        produce: '/api/system/shop/report/shop/bill/listShopProduceExport',
        picking: '/api/system/shop/report/shop/bill/listShopPickingExport',
        check: '/api/system/shop/report/shop/bill/listShopCheckExport'
      }, //定义导出枚举
      exportNameType: {
        receipt: '门店单据查询（门店订货查询）',
        materialOrder: '门店单据查询（门店原料订货查询）',
        purchase: '门店单据查询（门店采购查询）',
        scrap: '门店单据查询（门店报废查询）',
        shopIn: '门店单据查询（门店调入单查询）',
        shopOut: '门店单据查询（门店调出单查询）',
        produce: '门店单据查询（门店生产查询）',
        picking: '门店单据查询（门店原料消耗）',
        check: '门店单据查询（店盘点单查询）'
      }, //定义导出名称枚举
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  created () {
    this.business('shop_scrap_type')
    this.getInitializeData()
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable1.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    handleClick (tab) {
      this.getList()
    },
    // 初始化下拉框
    async getInitializeData () {
      //await this.getList("receipt");
      // 获取仓库详情
      // const res4 = await allStoreList()
      // this.storeList = res4.data
      // 获取调出仓库详情
      // const res5 = await outStoreAPI()
      // this.outStoreList = res5.data
      //获取单据详情
      // const res6 = await getOrderDetail(this.$route.query.billId);
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      //枚举
      const apiMap = {
        receipt: shopGoodsListAPI,
        materialOrder: materialOrderListAPI,
        purchase: shopPurchaseListAPI,
        scrap: shopScrapListAPI,
        shopIn: ShopInListAPI,
        shopOut: ShopOutListAPI,
        produce: shopProduceListAPI,
        picking: shopPickingListAPI,
        check: shopCheckListAPI
      }
      const apiFunc = apiMap[this.activeName]
      res = await apiFunc(this.queryParams)

      this.tableData = res.rows || []
      this.total = res.total
      this.loadingTable = false
    },

    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },

    // 跳转详情
    handleDetail (row) {
      const billId = row.billId
      const activeTab = this.activeName
      let routeName = ''
      let query = {}
      if (activeTab === 'receipt') {
        routeName = 'billOrderDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'materialOrder') {
        routeName = 'MaterialOrderDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'purchase') {
        routeName = 'StockInDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'scrap') {
        routeName = 'ScrapDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'shopIn') {
        routeName = 'MoveInDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'shopOut') {
        routeName = 'MoveOutDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'produce') {
        routeName = 'ProduceDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'picking') {
        routeName = 'WasteDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      } else if (activeTab === 'check') {
        routeName = 'CheckDetail'
        query = {
          billId: billId,
          type: 'Update'
        }
      }

      this.$router.push({
        name: routeName,
        query: query
      })
    },
    //订单明细表格合计
    getSummaries (param) {
      console.log(param)
      const { columns, data } = param
      let sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (
          column.property === 'unitQty' ||
          column.property === 'unitMoney' ||
          column.property === 'unitTaxMoney' ||
          column.property === 'unitPriceTaxMoney' ||
          column.property === 'importUnitQty' ||
          column.property === 'unitSaleMoney' ||
          column.property === 'adjust_qty' ||
          column.property === 'adjustMoney' ||
          column.property === 'lose_qty' ||
          column.property === 'loseMoney'
        ) {
          const values = data.filter(
            item => !(item[`${column.property}`] === undefined)
          )
          const values2 = values.map(i => Number(i[`${column.property}`]))
          sums[index] = values2.reduce((prev, curr) => {
            if (!isNaN(curr) && typeof curr === 'number') {
              return fcount([prev, curr], '+')
            } else {
              return prev
            }
          }, 0)
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 96px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
