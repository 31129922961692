<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="门店名称、门店编号、商品编码、条码、商品类别"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 x-f">
              <span class="fS14MR10">商品</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.goodsIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listGoods',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择商品',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'goods' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="门店销售对比" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              border
              :max-height="tableHeight"
              :height="tableHeight"
              show-summary
              :summary-method="getSummaries"
              :key="Math.random()"
            >
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="80"
                type="index"
              />
              <el-table-column
                label="商品编码"
                align="center"
                prop="goodsNo"
                min-width="120"
              />
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                min-width="120"
              />
              <el-table-column
                label="条码"
                align="center"
                prop="barcode"
                min-width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="单位"
                align="center"
                prop="unitName"
                min-width="100"
                show-overflow-tooltip
              />
              <!-- label="门店" -->
              <el-table-column
                :label="item.shopName"
                :prop="`${item.shopName}.${item.shopId}`"
                v-for="(item, index) in tableshopList"
                :key="index"
                align="center"
              >
                <template>
                  <el-table-column
                    label="数量"
                    align="center"
                    width="100"
                    :prop="'goodsShops.' + item.shopId + '.unitQty'"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{
                          payMoneyCompute(
                            item.shopId,
                            scope.row.goodsShops,
                            'unitQty'
                          )
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="销售数量占比%"
                    align="center"
                    width="120"
                    :prop="'goodsShops.' + item.shopId + '.unitQtyRate'"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{
                          payMoneyCompute(
                            item.shopId,
                            scope.row.goodsShops,
                            'unitQtyRate'
                          )
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="金额"
                    align="center"
                    width="100"
                    :prop="'goodsShops.' + item.shopId + '.unitMoney'"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{
                          payMoneyCompute(
                            item.shopId,
                            scope.row.goodsShops,
                            'unitMoney'
                          )
                        }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="销售金额占比%"
                    align="center"
                    width="120"
                    :prop="'goodsShops.' + item.shopId + '.unitMoneyRate'"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{
                          payMoneyCompute(
                            item.shopId,
                            scope.row.goodsShops,
                            'unitMoneyRate'
                          )
                        }}
                      </span>
                    </template>
                  </el-table-column>
                </template>
              </el-table-column>
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  listSaleContrastAPI,
  listSaleContrastSummaryAPI
} from '@/api/shop/report/saleRatio.js' //单据查询
import { fcount, isObject, isNumber } from '@/utils'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
export default {
  name: 'saleRatio',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote
  },

  data () {
    return {
      summary: [],
      summaryDateils: {},
      activeName: 'goods',
      tableshopList: [], //表格中的门店数据
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' },
        { parameter: 'goodsNos', name: '商品编号' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 74
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/shop/report/sale/contrast/listSaleContrastExport',
        {
          ...this.queryParams
        },
        `门店销售排名导出${getTimeDate()}.xlsx`
      )
    },
    //计算属性
    payMoneyCompute (shopId, arr, name) {
      if (arr) {
        const findIndex = arr.findIndex(item => item.shopId == shopId)
        if (findIndex != -1) {
          return arr[findIndex][name]
        } else {
          return 0
        }
      }
    },
    getSummaries1 ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            } else if (arr.length) {
              if (
                arr.length == 3 &&
                arr[2] != 'unitQtyRate' &&
                arr[2] != 'unitMoneyRate'
              ) {
                let sumsObj = this.summaryDateils?.goodsShops?.find(
                  v => v.shopId == arr[1]
                )
                sums[index] = sumsObj ? sumsObj[arr[2]] : ''
              }
            }
          } else {
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        } else if (index === 1 || index === 2 || index === 3) {
          sums[index] = ''
          return
        }

        const arr = column.property?.split?.('.') || []
        let values = data.map(item => Number(item[column.property]))
        if (arr.length === 3) {
          console.log('arr', arr)
          console.log('values', values)

          const [key, shopId, value] = arr
          if (value == 'unitQtyRate' || value == 'unitMoneyRate') {
            sums[index] = ''
            return
          }

          values = data.map(item =>
            Number(
              item[key]?.find?.(x => x.shopId == Number(shopId))?.[value] || 0
            )
          )
        }
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${Number(sums[index].toFixed(2))}`
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    handleClick (tab) {
      // this.queryParams.searchType = tab.name;
      this.getList()
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const res = await listSaleContrastAPI(this.queryParams)
      if (res?.rows?.length > 0) {
        this.tableshopList = res.rows[0].shops
      }
      this.tableData = res.rows
      this.total = res.total
      // const res1 = await listSaleContrastSummaryAPI(this.queryParams)
      // this.summaryDateils = res1.data || {}
      this.loadingTable = false
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
