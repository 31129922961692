var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "销售小票号详情" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        "show-summary": "",
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "70",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          prop: "goodsNo",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "条码",
                          align: "center",
                          prop: "barcode",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "goodsSpec",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "数量",
                          sortable: "",
                          align: "center",
                          prop: "unitQty",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "销售金额",
                          sortable: "",
                          align: "center",
                          prop: "saleMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "收款明细",
                        visible: _vm.dialogTableVisible,
                        width: "500px",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogTableVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-table",
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "date",
                              label: "收款方式",
                              width: "80",
                              prop: "payModeName",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "name",
                              label: "收款金额",
                              width: "100",
                              prop: "payMoney",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "address",
                              label: "支付卡号/帐号",
                              prop: "payCardNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }