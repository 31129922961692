<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :isBillDate="false"
            :is-search-input="true"
            is-search-input-title="门店编号、门店名称"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 marB10">
              <span class="fS14MR10">门店</span>
              <SelectRemote
                :multiple="true"
                v-model="queryParams.shopIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择门店',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'shop' }),
                            },
                          },
                        ],
                      },
                    },
                  }).option
                "
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店业绩统计" class="orderInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="月份销售完成比查询" name="saleMonth">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
                :height="tableHeight"
                :max-height="tableHeight"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="80"
                />
                <el-table-column label="门店" align="center" prop="shopName" />
                <el-table-column label="月份" align="center" prop="yearMonth" />
                <el-table-column
                  label="目标金额"
                  sortable
                  align="center"
                  prop="monthSale"
                />
                <el-table-column label="已完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="completeMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="completeRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.completeRate">
                        {{ scope.row.completeRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="未完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="incompleteMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="incompleteRate"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.row.incompleteRate">
                        {{ scope.row.incompleteRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                  label="剩余天数"
                  align="center"
                  prop="residueDay"
                />
                <el-table-column
                  label="剩余每日目标"
                  align="center"
                  prop="residueDailyGoal"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="年度销售完成比查询" name="saleYear">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
                :height="tableHeight"
                :max-height="tableHeight"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="80"
                />
                <el-table-column label="门店" align="center" prop="shopName" />
                <el-table-column label="年份" align="center" prop="yearMonth" />
                <el-table-column
                  label="目标金额"
                  sortable
                  align="center"
                  prop="monthSale"
                />
                <el-table-column label="已完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="completeMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="completeRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.completeRate">
                        {{ scope.row.completeRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="未完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="incompleteMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="incompleteRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.incompleteRate">
                        {{ scope.row.incompleteRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="月份充值完成比查询" name="rechargeMonth">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
                :height="tableHeight"
                :max-height="tableHeight"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="80"
                />
                <el-table-column label="门店" align="center" prop="shopName" />
                <el-table-column label="月份" align="center" prop="yearMonth" />
                <el-table-column
                  label="目标金额"
                  sortable
                  align="center"
                  prop="monthSale"
                />
                <el-table-column label="已完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="completeMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="completeRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.completeRate">
                        {{ scope.row.completeRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="未完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="incompleteMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="incompleteRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.incompleteRate">
                        {{ scope.row.incompleteRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                  label="剩余天数"
                  align="center"
                  prop="residueDay"
                />
                <el-table-column
                  label="剩余每日目标"
                  align="center"
                  prop="residueDailyGoal"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="年度充值完成比查询" name="rechargeYear">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :key="Math.random()"
                :height="tableHeight"
                :max-height="tableHeight"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="80"
                />
                <el-table-column label="门店" align="center" prop="shopName" />
                <el-table-column label="年份" align="center" prop="yearMonth" />
                <el-table-column
                  label="目标金额"
                  sortable
                  align="center"
                  prop="monthSale"
                />
                <el-table-column label="已完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="completeMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="completeRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.completeRate">
                        {{ scope.row.completeRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="未完成" align="center">
                  <el-table-column
                    label="金额"
                    sortable
                    align="center"
                    prop="incompleteMoney"
                  />
                  <el-table-column
                    label="百分比"
                    sortable
                    align="center"
                    prop="incompleteRate"
                    ><template slot-scope="scope">
                      <div v-if="scope.row.incompleteRate">
                        {{ scope.row.incompleteRate + "%" }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from "@/views/components/cardTitleCom"; // 卡片标题
import seniorSearch from "@/views/components/seniorSearch"; // 高级搜索
import bottomPage from "@/views/components/bottomPage"; // 底部分页
import {
  yearRateAPI,
  monthRateAPI,
  listRechargeYear,
  listRechargeMonth,
} from "@/api/shop/report/posPerformanceStat"; //销售完成比查询
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "posPerformanceStat",
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote,
  },
  data() {
    return {
      activeName: "saleMonth",
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "shopNos", name: "门店编号" },
        { parameter: "shopNames", name: "门店名称" },
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 130;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    // 分页
    handleClick(tab) {
      // this.queryParams.searchType = tab.name;
      this.getList();
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true;
      let res = {};
      if (this.activeName == "saleMonth") {
        res = await monthRateAPI(this.queryParams);
      } else if (this.activeName == "saleYear") {
        res = await yearRateAPI(this.queryParams);
      } else if (this.activeName == "rechargeMonth") {
        res = await listRechargeMonth(this.queryParams);
      } else if (this.activeName == "rechargeYear") {
        res = await listRechargeYear(this.queryParams);
      }
      this.tableData = res.rows;
      this.total = res.total;
      this.loadingTable = false;
    },

    // 是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    // 跳转详情
    handleDetail(row) {
      const billId = row.billId;
      this.$router.push({
        name: "apDetail",
        query: {
          billId: billId,
          type: "Update",
        },
      });
    },
    getSummaries(param) {
      //console.log("====", param, this.tableData);
      const { columns, data } = param;
      let sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        let name = "";
        if (column.property === "monthSale") {
          name = "monthSale";

          sums[index] = 0; // 初始化合计值为0
          sums[index] += " ";

          const values = data.map((item) => item[name]);

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr;
          }, sums[index]);
          // 累加到已初始化的合计值上
        } else if (column.property === "completeMoney") {
          name = "completeMoney";
          sums[index] = 0; // 初始化合计值为0
          sums[index] += " ";
          const values = data.map((item) => item[name]);
          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr;
          }, sums[index]);
          // 累加到已初始化的合计值上
        } else if (column.property === "incompleteMoney") {
          name = "incompleteMoney";
          sums[index] = 0; // 初始化合计值为0
          sums[index] += " ";
          const values = data.map((item) => item[name]);
          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr;
          }, sums[index]);
          // 累加到已初始化的合计值上
        } else if (column.property === "residueDay") {
          name = "residueDay";
          sums[index] = 0; // 初始化合计值为0
          sums[index] += " ";
          const values = data.map((item) => item[name]);
          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr;
          }, sums[index]);
          // 累加到已初始化的合计值上
        } else if (column.property === "residueDailyGoal") {
          name = "residueDay";
          sums[index] = 0; // 初始化合计值为0
          sums[index] += " ";
          const values = data.map((item) => item[name]);
          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr;
          }, sums[index]);
          // 累加到已初始化的合计值上
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .orderInfo {
    height: calc(100% - 94px);
    //单据表格
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
