<template>
  <div class="wrap" id="wrap">
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="销售小票号详情" class="headGoodsTable">
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="goodsMessage">
          <div>
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              show-summary
              border
              :max-height="tableHeight"
              :height="tableHeight"
              :summary-method="getSummaries"
            >
              <!-- <el-table-column type="selection" width="60" align="center" /> -->
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />
              <el-table-column
                label="收款时间"
                sortable
                align="center"
                prop="payTime"
              />
              <el-table-column
                label="收款方式"
                align="center"
                prop="payModeName"
              />
              <el-table-column
                label="小票号"
                align="center"
                prop="billNo"
                show-overflow-tooltip
              />
              <el-table-column
                label="收款金额"
                align="center"
                prop="payMoney"
              />
              <el-table-column
                v-if="payType"
                label="会员卡号"
                align="center"
                prop="vipNo"
              />
              <el-table-column
                v-if="payType"
                label="会员名称"
                align="center"
                prop="vipName"
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import { gatherDetailListAPI } from '@/api/shop/report/gatherQuery' //门店收款查询详情
export default {
  name: 'gatherQueryDetail',
  components: { bottomPage, cardTitleCom },
  data () {
    return {
      payType: true,
      // 门店数据
      shopInfoList: [],
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      paginationH: 40
    }
  },
  //路由每次刷新
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loadingTable = true
      if (vm.$route.query.type === 'Update') {
        // 初始化表格数据
        vm.payType = vm.$route.query.payType
        vm.queryParams = JSON.parse(vm.$route.query.shopObj)
        await vm.getList()
      }
      vm.loadingTable = false
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - 65
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true

      const { rows, total } = await gatherDetailListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    getSummaries (param) {
      const { columns, data } = param
      let sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let name = ''
        if (column.property === 'payMoney') {
          name = 'payMoney'

          sums[index] = 0 // 初始化合计值为0
          sums[index] += ' '

          const values = data.map(item => item[name])

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr
          }, sums[index]) // 累加到已初始化的合计值上
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //商品表格卡片
  .headGoodsTable {
    height: calc(100vh - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
