<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="收货人名称、手机号"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10 marB10 x-f">
              <div class="x-f">
                <span class="fS14MR10">取货日期</span>
                <el-date-picker
                  v-model="datetimeGet"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                  class="marR15"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </div>
              <div class="x-f">
                <span class="fS14MR10">状态</span>
                <SelectLocal
                  :multiple="true"
                  class="marR15"
                  v-model="queryParams.orderStatuss"
                  style="width: 250px"
                  :option="{
                    data: orderStatusList,
                    labels: [{ title: '名称', label: 'orderStatusName' }],
                    value: 'orderStatusId',
                    label: 'orderStatusName'
                  }"
                />
              </div>

              <div class="x-f marR15">
                <span class="fS14MR10">预定门店</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.predetermineShopIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listShop',
                      option: {
                        option: {
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择门店',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'shop' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </div>
              <div class="x-f marR15">
                <span class="fS14MR10">取货门店</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.shopIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listShop',
                      option: {
                        option: {
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择门店',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'shop' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </div>
            </div>
            <div class="x-f marR15">
              <span class="fS14MR10">配送模式</span>
              <SelectLocal
                :multiple="true"
                class="marR15"
                v-model="queryParams.fetchTypes"
                style="width: 250px"
                :option="{
                  data: dict.type.fetch_type,
                  labels: [{ title: '名称', label: 'label' }],
                  value: 'value',
                  label: 'label'
                }"
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="门店预订查询" class="orderInfo">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="预订单明细查询" name="detail">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :summary-method="getSummaries"
                :max-height="tableHeight"
                :height="tableHeight"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="预订时间"
                  align="center"
                  prop="billDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="预订小票号"
                  align="center"
                  prop="billNo"
                  width="180"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="收货人/客户名称"
                  align="center"
                  prop="receiverName"
                  minWidth="160"
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  prop="receiverPhone"
                  minWidth="160"
                />
                <el-table-column
                  label="会员卡号"
                  align="center"
                  prop="vipNo"
                  minWidth="160"
                />
                <el-table-column
                  label="会员名称"
                  align="center"
                  prop="vipName"
                  minWidth="160"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  width="120"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  width="120"
                />
                <el-table-column
                  label="商品规格"
                  align="center"
                  prop="goodsSpec"
                  width="120"
                />
                <el-table-column
                  label="单价"
                  sortable
                  align="center"
                  prop="unitPrice"
                  width="120"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="加值属性"
                  align="center"
                  prop="machining"
                  minWidth="120"
                />
                <el-table-column
                  label="收银机编号"
                  align="center"
                  prop="machineNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机"
                  align="center"
                  prop="machineName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                  minWidth="160"
                />
                <el-table-column
                  label="配送方式"
                  align="center"
                  prop="fetchTypeName"
                  width="120"
                />
                <el-table-column
                  label="配送/取货门店"
                  align="center"
                  prop="shopName"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="收货地址"
                  align="center"
                  prop="receiverRegionAddress"
                  minWidth="160"
                />
                <el-table-column
                  label="预订门店"
                  align="center"
                  prop="predetermineShopName"
                  minWidth="160"
                />
                <el-table-column
                  label="生产部门"
                  align="center"
                  prop="orderShopName"
                  width="120"
                />
                <el-table-column
                  label="取货小票号"
                  align="center"
                  prop="pickUpBillPosNo"
                  width="180"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="配送/取货时间"
                  align="center"
                  prop="reachDate"
                  minWidth="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="实际取货时间"
                  align="center"
                  prop="pickUpDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="状态"
                  align="center"
                  prop="orderStatusName"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="billRemark"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订单查询" name="summary">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="预订时间"
                  align="center"
                  prop="billDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="预订小票号"
                  align="center"
                  prop="billPosNo"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row.billId)"
                      target="_blank"
                    >
                      {{ scope.row.billPosNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="收货人/客户名称"
                  align="center"
                  prop="receiverName"
                  width="125"
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  prop="receiverPhone"
                  width="150"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="会员卡号"
                  align="center"
                  prop="vipNo"
                  minWidth="160"
                />
                <el-table-column
                  label="会员名称"
                  align="center"
                  prop="vipName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机编号"
                  align="center"
                  prop="machineNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机"
                  align="center"
                  prop="machineName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                  minWidth="160"
                />
                <el-table-column
                  label="配送方式"
                  align="center"
                  prop="fetchTypeName"
                  width="120"
                />
                <el-table-column
                  label="配送/取货门店"
                  align="center"
                  prop="shopName"
                  width="150"
                />
                <el-table-column
                  label="收货地址"
                  align="center"
                  prop="receiverRegionAddress"
                  width="120"
                />
                <el-table-column
                  label="预订门店"
                  align="center"
                  prop="predetermineShopName"
                  width="120"
                />
                <el-table-column
                  label="生产部门"
                  align="center"
                  prop="orderShopName"
                  width="120"
                />
                <el-table-column
                  label="取货小票号"
                  align="center"
                  prop="pickUpBillPosNo"
                  width="180"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="配送/取货时间"
                  align="center"
                  prop="reachDate"
                  minWidth="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="实际取货时间"
                  align="center"
                  prop="pickUpDate"
                  minWidth="160"
                />
                <el-table-column
                  label="状态"
                  align="center"
                  prop="orderStatusName"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="billRemark"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订收款查询" name="payment">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="预订时间"
                  align="center"
                  prop="billDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="预订小票号"
                  align="center"
                  prop="billPosNo"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row.billId)"
                      target="_blank"
                    >
                      {{ scope.row.billPosNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="收货人/客户名称"
                  align="center"
                  prop="receiverName"
                  width="125"
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  prop="receiverPhone"
                  width="150"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="会员卡号"
                  align="center"
                  prop="vipNo"
                  minWidth="160"
                />
                <el-table-column
                  label="会员名称"
                  align="center"
                  prop="vipName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机编号"
                  align="center"
                  prop="machineNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机"
                  align="center"
                  prop="machineName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                  minWidth="160"
                />
                <el-table-column
                  label="配送方式"
                  align="center"
                  prop="fetchTypeName"
                  width="120"
                />
                <el-table-column
                  label="配送/取货门店"
                  align="center"
                  prop="shopName"
                  width="150"
                />
                <el-table-column
                  label="收货地址"
                  align="center"
                  prop="receiverRegionAddress"
                  width="120"
                />
                <el-table-column
                  label="预订门店"
                  align="center"
                  prop="predetermineShopName"
                  width="120"
                />
                <el-table-column
                  label="生产部门"
                  align="center"
                  prop="orderShopName"
                  width="120"
                />
                <el-table-column
                  label="取货小票号"
                  align="center"
                  prop="pickUpBillPosNo"
                  width="180"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="配送/取货时间"
                  align="center"
                  prop="reachDate"
                  minWidth="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="实际取货时间"
                  align="center"
                  prop="pickUpDate"
                  minWidth="160"
                />
                <el-table-column
                  label="付款方式"
                  align="center"
                  prop="payModeId"
                >
                  <el-table-column
                    :label="item.payModeName"
                    :prop="`${item.payModeName}.${item.payModeId}`"
                    v-for="(item, index) in payModeList"
                    :key="index"
                    align="center"
                    sortable
                    width="120"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.payDetailList">
                        {{
                          payMoneyCompute(
                            item.payModeId,
                            scope.row.payDetailList
                          )
                        }}
                      </span>
                      <span v-else>0</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="状态"
                  align="center"
                  prop="orderStatusName"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="billRemark"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订退款查询" name="refund">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="预订时间"
                  align="center"
                  prop="billDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="预订小票号"
                  align="center"
                  prop="billPosNo"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click="handleDetail(scope.row.billId)"
                      target="_blank"
                    >
                      {{ scope.row.billPosNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="收货人/客户名称"
                  align="center"
                  prop="receiverName"
                  width="125"
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  prop="receiverPhone"
                  width="150"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="会员卡号"
                  align="center"
                  prop="vipNo"
                  minWidth="160"
                />
                <el-table-column
                  label="会员名称"
                  align="center"
                  prop="vipName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机编号"
                  align="center"
                  prop="machineNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银机"
                  align="center"
                  prop="machineName"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员编号"
                  align="center"
                  prop="cashierNo"
                  minWidth="160"
                />
                <el-table-column
                  label="收银员"
                  align="center"
                  prop="cashierName"
                  minWidth="160"
                />
                <el-table-column
                  label="配送方式"
                  align="center"
                  prop="fetchTypeName"
                  width="120"
                />
                <el-table-column
                  label="配送/取货门店"
                  align="center"
                  prop="shopName"
                  width="150"
                />
                <el-table-column
                  label="收货地址"
                  align="center"
                  prop="receiverRegionAddress"
                  width="120"
                />
                <el-table-column
                  label="预订门店"
                  align="center"
                  prop="predetermineShopName"
                  width="120"
                />
                <el-table-column
                  label="生产部门"
                  align="center"
                  prop="orderShopName"
                  width="120"
                />
                <el-table-column
                  label="取货小票号"
                  align="center"
                  prop="pickUpBillPosNo"
                  width="180"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="配送/取货时间"
                  align="center"
                  prop="reachDate"
                  minWidth="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="实际取货时间"
                  align="center"
                  prop="pickUpDate"
                  minWidth="160"
                />
                <el-table-column
                  label="付款方式"
                  align="center"
                  prop="payModeId"
                >
                  <el-table-column
                    :label="item.payModeName"
                    :prop="`${item.payModeName}.${item.payModeId}`"
                    v-for="(item, index) in payModeList"
                    :key="index"
                    align="center"
                    sortable
                    width="120"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.payDetailList">
                        {{
                          payMoneyCompute(
                            item.payModeId,
                            scope.row.payDetailList
                          )
                        }}
                      </span>
                      <span v-else>0</span>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                  label="收款金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="状态"
                  align="center"
                  prop="orderStatusName"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  prop="billRemark"
                  width="120"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订查询汇总(按日期)" name="collectDate">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="日期"
                  align="center"
                  prop="billDate"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                  minWidth="120"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订查询汇总(按门店)" name="collectShop">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="门店编码"
                  align="center"
                  prop="shopNo"
                  minWidth="160"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  prop="shopName"
                  minWidth="160"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                  minWidth="120"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="预订查询汇总(按商品)" name="collectGoods">
              <el-table
                ref="multipleTable"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
                :key="Math.random()"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  minWidth="120"
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  minWidth="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  minWidth="160"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  minWidth="120"
                />
                <el-table-column
                  label="商品规格"
                  align="center"
                  prop="goodsSpec"
                  minWidth="120"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="金额"
                  sortable
                  align="center"
                  prop="saleMoney"
                  minWidth="120"
                >
                </el-table-column>
                <el-table-column
                  label="总优惠金额"
                  sortable
                  align="center"
                  prop="agioMoney"
                  minWidth="120"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </el-tabs>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  orderListAPI,
  orderListSummaryAPI
} from '@/api/shop/report/posReserveQuery' // 销售列表
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import { fcount, isObject, isNumber } from '@/utils'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
export default {
  name: 'posReserveQuery',
  dicts: ['fetch_type'],
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote,
    SelectLocal
  },
  data () {
    return {
      summary: ['unitQty', 'saleMoney'],
      summaryDateils: {},
      //状态
      orderStatusList: [
        { orderStatusName: '预定中', orderStatusId: '1' },
        { orderStatusName: '生产中', orderStatusId: '2' },
        { orderStatusName: '已入库', orderStatusId: '3' },
        { orderStatusName: '已取货', orderStatusId: '4' },
        { orderStatusName: '取消预订', orderStatusId: '7' }
      ],
      payModeList: [], //付款方式列表
      activeName: 'detail',
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'receiverNames', name: '收货人' },
        { parameter: 'receiverPhones', name: '收货人电话' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      exportType: {
        detail:
          '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
        summary:
          '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport',
        refund:
          '/api/system/shop/base/predetermineOrder/getShopPredetermineOrderListExport'
      }, //定义导出枚举
      exportNameType: {
        detail: '门店预定查询（预订单明细查询）',
        summary: '门店预定查询（预订单汇总查询）',
        refund: '门店预定查询（预订退货查询）'
      }, //定义导出名称枚举
      datetimeGet: undefined,
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable1.doLayout()
    })
  },
  watch: {
    datetimeGet (newVal) {
      if (newVal) {
        this.queryParams.pickupBeginBillDate = newVal[0]
        this.queryParams.pickupEndBillDate = newVal[1]
      } else {
        this.queryParams.pickupBeginBillDate = undefined
        this.queryParams.pickupEndBillDate = undefined
      }
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //计算属性
    payMoneyCompute (payModeId, arr) {
      if (arr) {
        const findIndex = arr.findIndex(item => item.payModeId == payModeId)
        if (findIndex != -1) {
          return arr[findIndex].payMoney
        } else {
          return 0
        }
      }
    },
    // 分页
    async handleClick (tab) {
      this.queryParams.searchType = tab.name
      await this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      try {
        this.loadingTable = true
        const res = await orderListAPI(this.queryParams)
        this.tableData = res.rows
        this.total = res.total
        const res1 = await orderListSummaryAPI(this.queryParams)
        this.summaryDateils = res1.data || {}
        this.payModeList = this.summaryDateils?.payTypes || []
      } catch (error) {
      } finally {
        this.loadingTable = false
      }
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    // 跳转详情
    handleDetail (billId) {
      this.$router.push({
        name: 'posReserveQueryDetail',
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem || this.summaryDateils != '{}') {
          if (this.summaryDateils) {
            const arr = column?.property?.split('.')
            if (arr.length == 1) {
              sums[index] = this.summaryDateils[column.property] || ''
            } else if (arr.length) {
              if (arr.length > 1) {
                let sumsObj = this.summaryDateils?.payDetailList?.find(
                  v => v.payModeId == arr[1]
                )
                sums[index] = sumsObj ? sumsObj.payMoney : ''
              }
            }
          } else {
            console.log('sumItem1', sumItem)
            const arr = column.property.split('.')
            let values = data.map(x => Number(x[column.property] || 0))
            if (arr?.length === 4) {
              const [p, i, key, s] = arr
              values = data.map(
                x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
              )
            }
            sums[index] = `${values.reduce(
              (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
              0
            )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .orderInfo {
    height: calc(100% - 94px);
    //单据表格
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}

//单据日期
::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
}
</style>
