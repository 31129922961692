<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :is-search-input="true"
            is-search-input-title="输入收银员编号、名称，门店编号、名称"
            @isShowHighCom="getIsShowHigh"
          />
          <!-- 条件 -->
          <div v-show="showHigh" class="marT10">
            <div class="x-f">
              <span class="fS14MR10">收款类型</span>
              <SelectLocal
                :multiple="true"
                class="marR15"
                v-model="queryParams.payTypeIds"
                style="width: 250px"
                :option="{
                  data: payTypeList,
                  labels: [{ title: '名称', label: 'payTypeName' }],
                  value: 'payTypeId',
                  label: 'payTypeName'
                }"
              />
              <span class="fS14MR10">收款方式</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.payModeIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listPayClass',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收款方式',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'paymode' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <span class="fS14MR10">收银员</span>
              <SelectRemote
                :multiple="true"
                class="marR15"
                v-model="queryParams.cashierIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listCashier',
                    option: {
                      option: {
                        buttons: [
                          {
                            type: 'more',
                            option: {
                              title: '选择收银员',
                              width: 1250,
                              type: 'TreeAndTable',
                              formData: this.$dialog({ key: 'cashier' })
                            }
                          }
                        ]
                      }
                    }
                  }).option
                "
              />
              <div class="x-f">
                <span class="fS14MR10 marL15">门店</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.shopIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listShop',
                      option: {
                        option: {
                          buttons: [
                            {
                              type: 'more',
                              option: {
                                title: '选择门店',
                                width: 1250,
                                type: 'TreeAndTable',
                                formData: this.$dialog({ key: 'shop' })
                              }
                            }
                          ]
                        }
                      }
                    }).option
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="门店收款查询" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table
              name="gather"
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              show-summary
              :summary-method="getSummaries"
              border
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="80"
                type="index"
              />
              <el-table-column
                show-overflow-tooltip
                label="门店编号"
                align="center"
                prop="shopNo"
              />

              <el-table-column
                show-overflow-tooltip
                label="门店名称"
                align="center"
                prop="shopName"
              />
              <el-table-column label="收款类型" align="center" prop="payType" />
              <el-table-column
                label="收银员编号"
                align="center"
                prop="cashierNo"
              />
              <el-table-column
                label="收银员名称"
                align="center"
                prop="cashierName"
              />
              <el-table-column
                label="收款方式"
                align="center"
                prop="payModeName"
                width="160"
              >
              </el-table-column>
              <el-table-column
                label="收款金额"
                align="center"
                sortable
                prop="payMoney"
              >
                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    @click="handleDetail(scope.row)"
                    target="_blank"
                  >
                    {{ scope.row.payMoney }}
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import { gatherListAPI } from '@/api/shop/report/gatherQuery' //门店收款查询
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { fcount, isNumber } from '@/utils'
export default {
  name: 'gatherQuery',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectRemote,
    SelectLocal
  },
  data () {
    return {
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'cashierNos', name: '收银员编号' },
        { parameter: 'cashierNames', name: '收银员名称' },
        { parameter: 'shopNos', name: '门店编号' },
        { parameter: 'shopNames', name: '门店名称' }
      ],
      //收款类型
      payTypeList: [
        { payTypeName: '充值', payTypeId: '1' },
        { payTypeName: '销售', payTypeId: '2' },
        { payTypeName: '预定', payTypeId: '3' }
      ],
      // 是否显示高级搜索
      showHigh: false,
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 74
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/shop/report/sale/pay/listPayExport',
        {
          ...this.queryParams
        },
        `门店收款查询导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const res = await gatherListAPI(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },

    // 跳转详情
    handleDetail (row) {
      // console.log("row", row);
      const obj = {
        pageNum: 1,
        pageSize: 15,
        cashierId: row.cashierId,
        payModeId: row.payModeId,
        shopId: row.shopId,
        beginBillDate: this.queryParams.beginBillDate,
        endBillDate: this.queryParams.endBillDate,
        payTypeId: row.payTypeId
      }
      const shopObj = JSON.stringify(obj)
      this.$router.push({
        name: 'gatherQueryDetail',
        query: {
          shopObj: shopObj,
          payType: row.payType == '充值' ? true : false,
          type: 'Update'
        }
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const other = ['payMoney']
        const [key, orderStoreId] = column.property?.split?.('.') || []
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map(x => {
            let num = Number(x[column.property] || 0)
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.(
                  x => x.orderStoreId === orderStoreId
                )?.[key] || 0
              )
            }
            return num
          })
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
            0
          )
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
