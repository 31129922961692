<template>
  <div class="wrap" id="wrap">
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="销售小票号详情" class="headGoodsTable">
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              show-summary
              border
              :max-height="tableHeight"
              :height="tableHeight"
              :summary-method="getSummaries"
            >
              <el-table-column type="selection" width="70" align="center" />
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />

              <el-table-column label="商品编码" align="center" prop="goodsNo" />
              <el-table-column
                label="条码"
                align="center"
                prop="barcode"
                show-overflow-tooltip
              />
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
              />
              <el-table-column label="规格" align="center" prop="goodsSpec" />
              <el-table-column label="单位" align="center" prop="unitName" />

              <el-table-column
                label="数量"
                sortable
                align="center"
                prop="unitQty"
              />
              <el-table-column
                label="销售金额"
                sortable
                align="center"
                prop="saleMoney"
              />
              <!--                                <el-table-column-->
              <!--                                    label="支付卡号/账号"-->
              <!--                                    align="center"-->
              <!--                                    prop="payCardNo"-->
              <!--                                    width="150"-->
              <!--                                >-->
              <!--                                    <template slot-scope="scope">-->
              <!--                                        <el-button-->
              <!--                                            size="mini"-->
              <!--                                            type="primary"-->
              <!--                                            @click="showPayDetail(scope.row.billNo)"-->
              <!--                                        >查看详情-->
              <!--                                        </el-button-->
              <!--                                        >-->
              <!--                                    </template>-->
              <!--                                </el-table-column>-->
            </el-table>

            <el-dialog
              title="收款明细"
              :visible.sync="dialogTableVisible"
              width="500px"
            >
              <!-- :data="payData" -->
              <el-table>
                <el-table-column
                  property="date"
                  label="收款方式"
                  width="80"
                  prop="payModeName"
                ></el-table-column>
                <el-table-column
                  property="name"
                  label="收款金额"
                  width="100"
                  prop="payMoney"
                ></el-table-column>
                <el-table-column
                  property="address"
                  label="支付卡号/帐号"
                  prop="payCardNo"
                ></el-table-column>
              </el-table>
            </el-dialog>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  billDetailListAPI,
  billPayDetailListAPI
} from '@/api/shop/report/saleTotal' // 门店销售列表
export default {
  name: 'saleTotalDetail',
  components: { bottomPage, cardTitleCom },
  data () {
    return {
      dialogPayData: [], // 收款明细的数据
      dialogTableVisible: false,
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        billId: null
      },
      vivwH: 0, //页面高度
      paginationH: 40
    }
  },
  //路由每次刷新
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loadingTable = true
      console.log('Update == ', vm.$route.query)
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          vm.queryParams.billId = vm.$route.query.billId
            ? vm.$route.query.billId
            : ''
          // 初始化表格数据
          await vm.getList()
        }
      }
      vm.loadingTable = false
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - 65
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await billDetailListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    async showPayDetail (billNo) {
      try {
        const response = await billPayDetailListAPI(billNo)
        this.dialogPayData = response.data // 将收款明细的数据赋值给 dialogPayData
        this.dialogTableVisible = true // 打开对话框
      } catch (error) {
        console.error('Error fetching pay detail:', error)
      }
    },
    //计算
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        let name = ''
        if (column.property === 'unitQty') {
          name = 'unitQty'

          sums[index] = 0 // 初始化合计值为0
          sums[index] += ' '

          const values = data.map(item => item[name])

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr
          }, sums[index]) // 累加到已初始化的合计值上
        } else if (column.property === 'unitMoney') {
          name = 'unitMoney'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //商品表格卡片
  .headGoodsTable {
    height: calc(100vh - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
