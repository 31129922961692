var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "销售小票号详情" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "goodsMessage" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        "show-summary": "",
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "收款时间",
                          sortable: "",
                          align: "center",
                          prop: "payTime",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "收款方式",
                          align: "center",
                          prop: "payModeName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "小票号",
                          align: "center",
                          prop: "billNo",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "收款金额",
                          align: "center",
                          prop: "payMoney",
                        },
                      }),
                      _vm.payType
                        ? _c("el-table-column", {
                            attrs: {
                              label: "会员卡号",
                              align: "center",
                              prop: "vipNo",
                            },
                          })
                        : _vm._e(),
                      _vm.payType
                        ? _c("el-table-column", {
                            attrs: {
                              label: "会员名称",
                              align: "center",
                              prop: "vipName",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }