<template>
  <div class="wrap" id="wrap">
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="销售小票号详情" class="headGoodsTable">
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table ref="multipleTable" v-loading="loadingTable" tooltip-effect="dark" :data="tableData" show-summary
              border :max-height="tableHeight" :height="tableHeight" :summary-method="getSummaries">
              <el-table-column type="selection" width="70" align="center" />
              <el-table-column label="序号" align="center" width="80" type="index" />
              <el-table-column v-for="(item, index) in columnList" :key="index" :label="item.label" align="center"
                :prop="item.prop" :minWidth="item.minWidth" />
              <!--                                <el-table-column-->
              <!--                                    label="支付卡号/账号"-->
              <!--                                    align="center"-->
              <!--                                    prop="payCardNo"-->
              <!--                                    width="150"-->
              <!--                                >-->
              <!--                                    <template slot-scope="scope">-->
              <!--                                        <el-button-->
              <!--                                            size="mini"-->
              <!--                                            type="primary"-->
              <!--                                            @click="showPayDetail(scope.row.billNo)"-->
              <!--                                        >查看详情-->
              <!--                                        </el-button-->
              <!--                                        >-->
              <!--                                    </template>-->
              <!--                                </el-table-column>-->
            </el-table>

            <el-dialog title="收款明细" :visible.sync="dialogTableVisible" width="500px">
              <!-- :data="payData" -->
              <el-table>
                <el-table-column property="date" label="收款方式" width="80" prop="payModeName"></el-table-column>
                <el-table-column property="name" label="收款金额" width="100" prop="payMoney"></el-table-column>
                <el-table-column property="address" label="支付卡号/帐号" prop="payCardNo"></el-table-column>
              </el-table>
            </el-dialog>
            <bottomPage v-model="queryParams" :getList="getList" :is-select-all="false" :total-com="total" />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import { franchiseListBillNoDetail } from '@/api/joinClient/report/saleTotal' // 加盟销售
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import { listBillNoDetail } from '@/api/shop/report/saleTotal' // 门店销售列表
import { paymentDetailsFill } from '@/api/shop/analyse/posGather'
export default {
  name: 'saleTotalDetail',
  components: { bottomPage, cardTitleCom },
  data() {
    return {
      dialogPayData: [], // 收款明细的数据
      dialogTableVisible: false,
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        billNo: null
      },
      vivwH: 0, //页面高度
      paginationH: 40,
      columnList: [
        {
          label: '商品编码',
          prop: 'goodsNo',
          minWidth: 160
        },
        {
          label: '商品名称',
          prop: 'goodsName',
          minWidth: 160
        },
        {
          label: '条码',
          prop: 'barcode',
          minWidth: 160
        },
        {
          label: '规格',
          prop: 'goodsSpec',
          minWidth: 120
        },
        {
          label: '单位',
          prop: 'unitName',
          minWidth: 120
        },
        {
          label: '数量',
          prop: 'unitQty',
          minWidth: 120
        },
        {
          label: '销售价格',
          prop: 'unitPrice',
          minWidth: 120
        },
        {
          label: '销售金额',
          prop: 'unitMoney',
          minWidth: 120
        }
      ]
    }
  },
  //路由每次刷新
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loadingTable = true
      console.log('Update == ', vm.$route.query)
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billNo) {
          vm.columnList = [
            {
              label: '商品编码',
              prop: 'goodsNo',
              minWidth: 160
            },
            {
              label: '商品名称',
              prop: 'goodsName',
              minWidth: 160
            },
            {
              label: '条码',
              prop: 'barcode',
              minWidth: 160
            },
            {
              label: '规格',
              prop: 'goodsSpec',
              minWidth: 120
            },
            {
              label: '单位',
              prop: 'unitName',
              minWidth: 120
            },
            {
              label: '数量',
              prop: 'unitQty',
              minWidth: 120
            },
            {
              label: '销售价格',
              prop: 'unitPrice',
              minWidth: 120
            },
            {
              label: '销售金额',
              prop: 'unitMoney',
              minWidth: 120
            }
          ]
          vm.queryParams = { pageNum: 1, pageSize: 15 }
          vm.queryParams.billNo = vm.$route.query.billNo
          // 初始化表格数据
          await vm.getList()
        } else if (vm.$route.query.billId) {
          vm.columnList = [
            {
              label: '充值小票号',
              prop: 'billNo',
              minWidth: 180
            },
            {
              label: '日期',
              prop: 'createTime',
              minWidth: 155
            },
            {
              label: '门店编号',
              prop: 'shopNo',
              minWidth: 160
            },
            {
              label: '门店名称',
              prop: 'shopName',
              minWidth: 160
            },
            {
              label: '收银员编号',
              prop: 'cashierNo',
              minWidth: 120
            },
            {
              label: '收银员',
              prop: 'cashierName',
              minWidth: 120
            },
            {
              label: '级别编号',
              prop: 'vipLevelNo',
              minWidth: 150
            },
            {
              label: '级别名称',
              prop: 'vipLevelName',
              minWidth: 150
            },
            {
              label: '会员卡号',
              prop: 'vipNo',
              minWidth: 180
            },
            {
              label: '会员名称',
              prop: 'vipName',
              minWidth: 150
            },
            {
              label: '手机号',
              prop: 'tel',
              minWidth: 150
            },
            {
              label: '卡状态',
              prop: 'cardStatusName',
              minWidth: 120
            },
            {
              label: '充值金额',
              prop: 'fillMoney',
              minWidth: 120
            },
            {
              label: '赠送金额',
              prop: 'largessMoney',
              minWidth: 120
            }
          ]
          vm.queryParams = { pageNum: 1, pageSize: 15 }
          vm.queryParams.billId = vm.$route.query?.billId
          await vm.getList()
        }
      }
      vm.loadingTable = false
    })
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - 65
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    /** 查询表格列表 */
    async getList() {
      this.loadingTable = true
      let res = {}
      if (this.queryParams.billId) {
        res = await paymentDetailsFill(this.queryParams)
        this.tableData = res.data ? [res.data] : []
        this.total = res.data ? 1 : 0
      } else {
        if (this.$route.query?.shopType == 2) {
          res = await franchiseListBillNoDetail(this.queryParams)
        } else {
          res = await listBillNoDetail(this.queryParams)
        }
        this.tableData = res.rows
        this.total = res.total
      }

      this.loadingTable = false
    },
    async showPayDetail(billNo) {
      // try {
      //   const response = await billPayDetailListAPI(billNo)
      //   this.dialogPayData = response.data // 将收款明细的数据赋值给 dialogPayData
      //   this.dialogTableVisible = true // 打开对话框
      // } catch (error) {
      //   console.error('Error fetching pay detail:', error)
      // }
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        let name = ''
        if (column.property === 'unitQty') {
          name = 'unitQty'

          sums[index] = 0 // 初始化合计值为0
          sums[index] += ' '

          const values = data.map(item => item[name])

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr
          }, sums[index]) // 累加到已初始化的合计值上
          sums[index] = `${Number(Number(sums[index]).toFixed(2))}`
        } else if (column.property === 'unitMoney') {
          name = 'unitMoney'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
          sums[index] = `${Number(Number(sums[index]).toFixed(2))}`
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //商品表格卡片
  .headGoodsTable {
    height: calc(100vh - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
